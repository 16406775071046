
import rules from "@/mixins/rules"
import { mdiVideoPlus, mdiClose, mdiCheck } from "@mdi/js"
import Button from "@/components/base/Button"
import VideoUploader from "@/components/base/video/VideoUploader"

export default {
    name: "UploadVideo",
    components: {
        Button,
        VideoUploader,
        VideoUploadAlert: () =>
            import("@/components/base/video/VideoUploadAlert"),
    },
    mixins: [rules],
    emits: ["close-edit", "rollback-edit", "set-video-url", "fileSelect"],
    props: {
        isEdit: {
            type: Boolean,
            default: false,
        },
        largeBox: {
            type: Boolean,
            default: false,
        },
        indeterminate: {
            type: Boolean,
            default: false,
        },
        enableAlert: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            icons: {
                mdiClose,
                mdiVideoPlus,
                mdiCheck,
            },
            progress: 0,
            videoSucceeded: false,
            uploadRunning: false,
            file: null,
            fileTypeError: null,
        }
    },
    methods: {
        onUploading(currentFile) {
            if (currentFile) {
                this.file = currentFile
                this.$emit("fileSelect", currentFile)
                this.videoSucceeded = false
                this.uploadRunning = true
            } else {
                this.uploadRunning = false
            }
        },
        onSuccess(videoUrl) {
            this.videoSucceeded = true
            this.$emit("set-video-url", videoUrl)
        },
        onError(errorType) {
            this.videoSucceeded = false
            this.fileTypeError = errorType
        },
        onUpdateProgress(currentProgress) {
            this.progress = parseInt(currentProgress)
        },
    },
}
