
import axios from "axios"
import tus from "tus-js-client"
import { mapGetters } from "vuex"
export default {
    name: "VideoUploader",
    emits: ["uploading", "success", "update-progress", "error"],
    props: {
        /** Allow drop */
        drop: {
            type: Boolean,
            default: true,
        },
        /** Allowed video ratios either a Number or an Array of 2 values */
        ratios: {
            type: [Array, Number],
            default: () => [0.5, 2],
            validator: (val) =>
                typeof val === "number" ? val > 0 : val.length === 2,
        },
        /** Allowed formats */
        acceptedFormats: {
            type: Array,
            default: () => [
                "video/mp4",
                "video/mov",
                "video/wmv",
                "video/avi",
                "video/flv",
                "video/quicktime",
            ],
        },
    },
    data: () => ({
        progress: 0,
        currentFile: null,
    }),
    mounted() {
        this.$refs.video.addEventListener("loadedmetadata", this.processFile)
    },
    beforeDestroy() {
        this.$refs.video.removeEventListener("loadedmetadata", this.processFile)
    },
    computed: {
        ...mapGetters("meta", ["meta"]),
    },
    methods: {
        checkRatio() {
            if (!this.ratios) return true
            const tolerance = 0.01
            const videoRatio =
                this.$refs.video.videoWidth / this.$refs.video.videoHeight
            if (typeof this.ratios === "number")
                return Math.abs(this.ratios - videoRatio) < tolerance
            return (
                Math.min(...this.ratios) - tolerance < videoRatio &&
                videoRatio < Math.max(...this.ratios) + tolerance
            )
        },
        checkVideoType(type) {
            return this.acceptedFormats.includes(type)
        },
        openFileDialog() {
            this.$refs.input.click()
        },
        initUploading(e) {
            let files = []
            if (e.type === "drop") {
                if (!this.drop) return
                files = e.dataTransfer.files
            } else if (e.type === "input") files = e.target.files
            if (files.length === 0) return
            if (!this.checkVideoType(files[0].type)) {
                this.$emit("error", "format")
                return
            }
            this.currentFile = files[0]
            this.$emit("update-progress", this.progress)
            this.$refs.video.src = URL.createObjectURL(files[0])
        },
        processFile() {
            if (!this.checkRatio()) {
                this.$emit("error", "ratio")
                return
            }
            this.uploadFile()
        },
        async uploadFile() {
            if (!this.currentFile) return
            if (this.meta.api?.disabled) {
                this.$emit("uploading", this.currentFile)
                this.$emit("update-progress", 100)
                this.$emit("success")
                return
            }
            const instance = axios.create()
            this.$emit("uploading", this.currentFile)
            instance.defaults.headers.common["Content-Type"] =
                "application/json"
            instance.defaults.headers.common["Accept"] =
                "application/vnd.vimeo.*+json;version=3.4"
            instance.defaults.headers.common["Authorization"] =
                this.$config.vimeoToken
            const response = await instance.post(
                "https://api.vimeo.com/me/videos",
                {
                    upload: {
                        approach: "tus",
                        size: this.currentFile.size,
                    },
                    name: this.currentFile.name,
                }
            )
            let upload = new tus.Upload(this.currentFile, {
                uploadUrl: response.data.upload.upload_link,
                retryDelays: [0, 3000, 5000, 10000, 20000],
                metadata: {
                    filename: this.currentFile.name,
                    filetype: this.currentFile.type,
                },
                onError: (error) => {
                    this.$emit("is-uploading", null)
                    this.$emit("error", "internal", error)
                    this.currentFile = null
                },
                onProgress: (bytesUploaded, bytesTotal) => {
                    this.progress = (
                        (bytesUploaded / bytesTotal) *
                        100
                    ).toFixed(2)
                    this.$emit("update-progress", this.progress)
                },
                onSuccess: () => {
                    this.$emit("uploading", null)
                    this.$emit("success", response.data.link)
                    this.currentFile = null
                },
            })
            upload.start()
            return true
        },
    },
}
