/*
    Contains typical rules for pattern-matching text fields

    - Usage:            Just like referencing normal rule-methods in an array :rules="[phone]" Exception: min/max/or
    - Concatenation:    Use or(rule1,rule2) to check if at least one is valid.
                        This combines the two rules using their short naming.
    - New Rules:        When adding new rules, make sure to fulfill following requirements:
                        - name the function and use lambda notation with '(v, short = false)' with v being the string to validate
                        - start with '!v ||' to make the rule not required by default (use required as separate rule)
                        - end with '(short ? "<Short Name>" : "<Long Response>")' Short is used to combine rules via "or"
*/
const REGEXP = {
    EMAIL: /^\w([.-]?[\wöäüÖÄÜß])*@\w+([.-]?\w+)*(\.\w{2,})+$/, //original /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/
    PASSWORD:
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9*.!@$%^&(){}[\]:;<>,?/~_+\-=|\\]).{8,}$/,
    PHONE: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
    NUMBERS_ONLY: /^[0-9]*$/u,
    LETTERS_ONLY: /^\p{L}+$/u,
    DATE: /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/u,
    FILE_NAME: /^[\w\-. ]+$/,
    WEBSITE:
        /(https?:\/\/)?[-a-zA-Z0-9@:%._+~#=]{5,256}\.[a-zA-Z()]{2,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
}
const rules = {
    methods: {
        or(f1, f2) {
            return (v) => {
                if (f1(v) != true && f2(v) != true) {
                    return (
                        f1(v, true) + " / " + f2(v, true) + " muss valide sein"
                    )
                }
                return true
            }
        },
        ruleWebsite: (v, short = false) =>
            !v ||
            REGEXP.WEBSITE.test(v) ||
            (short ? "Webadresse" : "Die Webadresse ist nicht gültig"),
        ruleRequired:
            (msg = "Dies ist ein Pflichtfeld") =>
            (v) =>
                !!v || msg,
        /** Do ONLY use in lazy async evaluation, this is too slow as realtime rule */
        ruleEmail: (v, short = false) =>
            !v ||
            REGEXP.EMAIL.test(v) ||
            (short ? "E-Mail" : "Die E-Mail Adresse muss valide sein"),
        rulePassword: (v, short = false) =>
            !v ||
            REGEXP.PASSWORD.test(v) ||
            (short
                ? "Passwort"
                : "Das Passwort benötigt: Min. 8 Zeichen, Groß- und Kleinbuchstaben, eine Zahl oder Sonderzeichen"),
        rulePhone: (v, short = false) =>
            !v ||
            REGEXP.PHONE.test(v) ||
            (short ? "Telefonnummer" : "Die Telefonnummer muss valide sein"),
        ruleEquals(original) {
            return (v, short = false) =>
                !v ||
                v === original ||
                (short ? "Gleichheit" : "Felder müssen übereinstimmen")
        },
        ruleNumbersOnly: (v, short = false) =>
            !v ||
            REGEXP.NUMBERS_ONLY.test(v) ||
            (short ? "Nur Zahlen" : "Nur Zahlen erlaubt"),
        ruleLettersOnly: (v, short = false) =>
            !v ||
            REGEXP.LETTERS_ONLY.test(v) ||
            (short ? "Nur Buchstaben" : "Nur Buchstaben erlaubt"),
        ruleDate: (v, short = false) =>
            !v ||
            REGEXP.DATE.test(v) ||
            (short ? "Nur Datum" : "Nur Datum (z.B. 20.04.2001) erlaubt"),
        ruleYear: (v, short = false) =>
            !v ||
            v.length === 4 ||
            (short ? "Nur Jahreszahl" : "Nur Jahreszahl angeben"),
        // allow dashes between names
        ruleMin(min) {
            return (v, short = false) => {
                return (
                    !v ||
                    v.length >= min ||
                    (short ? "Mindestlänge" : `Mindestens ${min} Zeichen`)
                )
            }
        },
        ruleMax(max) {
            return (v, short = false) => {
                return (
                    !v ||
                    v.length <= max ||
                    (short ? "Maximallänge" : `Maximal ${max} Zeichen`)
                )
            }
        },
        /** checks if a Number is between two specified values */
        ruleBetween(min, max) {
            return (v, short = false) => {
                return (
                    !v ||
                    (v <= max && v >= min) ||
                    (short
                        ? "Wert ungültig"
                        : `Nur Werte von ${min} bis ${max} erlaubt`)
                )
            }
        },
        ruleIdentical(value) {
            return (v, short = false) => {
                return (
                    !v ||
                    v === value ||
                    (short
                        ? "Übereinstimmung"
                        : "Die Passwörter stimmen nicht überein!")
                )
            }
        },
        ruleNoSpace: (v, short = false) =>
            !v ||
            !v.includes(" ") ||
            (short ? "Keine Leerzeichen" : "Keine Leerzeichen erlaubt"),
        ruleFileName: (v, short = false) =>
            !v ||
            REGEXP.FILE_NAME.test(v) ||
            (short ? "Dateiname" : "Ungültiger Dateiname"),
    },
}
export default rules
